.header-content {
	margin-top: 30px;
}

.crumbs {
	@extend %listreset;
	display: flex;
	padding-left: 30px;
	margin-bottom: 15px;
	li {
		font-size: 14px;
		color: #fff;
		a {
			color: inherit;
			&:hover {color: inherit;}
		}

		&:not(:last-child):after {
			content: '/';
			display: inline-block;
			padding: 0 10px;
		}
	}
}


.page-section {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: -520px;
	height: calc(100% + 520px);
}

.page-content {
	flex-grow: 1;
	width: 100%;
	background: #fff;
	padding: 30px;
	border-radius: 16px;
	box-shadow: 
		0px 19px 38px rgba(#000, 0.3), 
		0px 15px 12px rgba(#000, 0.22);
	overflow: hidden;
}

.page-header {
	margin-bottom: 15px;
	color: #1D1D1B;
	font-weight: normal;
	font-size: 40px;
}

.page-text {
	margin-bottom: 30px;
	p {
		margin-bottom: 0;
		strong {font-weight: 500;}
	}
	h3 {
		font-family: $FONT_FAMILY_2;
		font-weight: bold;
		font-size: 24px;
		small {
			font-weight: normal;
			font-size: 18px;
			font-family: $FONT_FAMILY;
		}
	}
}

.page-pagination {
	margin-left: 30px;
}


.open-page {
	padding: 0;
}

.open-page__header {
	padding: 30px 0 0 30px;
	border-radius: 16px 16px 0px 0px;
	background: linear-gradient(90deg, #971E24 0%, #E94E1B 100%);
	color: #fff;
}

.open-page__body {
	padding: 30px;
}

.open-page__title {
	color: #fff;
}