.page-footer {
	padding: 25px 0;
	background-color: #8E8E8D;
}

.page-footer__links {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px 15px;
	.page-footer__link {
		margin: 0 15px;
	}
}

.page-footer__link {
	display: inline-block;
	padding-bottom: 3px;
	border-bottom: 1px solid transparent;
	font-size: 14px;
	color: #fff;
	&:hover {
		color: #fff;
		border-bottom-color: #fff;
	}
}

.page-copyright {
	margin-bottom: 0;
	color: #ECDBDF;
	font-size: 14px;
}