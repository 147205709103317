.footer {
	flex-shrink: 0;
	padding-bottom: 55px;
	background: linear-gradient(90deg, #830D29 0%, #E94E1B 100%);
	color: #fff;
	overflow: hidden;
}

.f-content {
	padding-top: 65px;
	margin-bottom: 35px;
}

.f-logo {
	display: block;
	margin-bottom: 30px;
}


.f-address {}

.f-address__item {
	display: block;
	margin-bottom: 18px;
	color: inherit;
	font-weight: 500;
	font-size: 20px;
	&:hover {
		color: inherit;
	}
	
	&.f-phone {
		font-size: 28px;
	}
}



.f-nav {}

.f-nav__list {
	@extend %listreset;
	border-left: 1px solid #F4A68D;
	padding-left: 24px;
}

.f-nav__item {
	position: relative;
	display: block;
	padding: 9px 0;
	color: #fff;
	line-height: 1.2;
	font-size: 18px;
	
	&:before {
		content: '';
		position: absolute;
		left: -30px; top: 50%;
		transform: translateY(-50%) rotateY(90deg);
		display: inline-block;
		width: 11px;
		height: 11px;
		border-radius: 50%;
		background-color: #fff;

		@include animate(transform);
	}
	
	&:hover {
		color: #fff;
	}

	&:hover, .active > & {
		&:before {
			transform: translateY(-50%) rotateY(0);
		}
	}
}



.copyright {
	margin-bottom: 0;
	font-weight: 500;
	font-size: 16px;
}