.tags {
	margin: 0 -15px -8px;
	.tag {
		margin: 0 15px 8px;
	}
}

.tag {
	display: inline-block;
	border: 1px solid #830D29;
	color: #830D29;
	font-size: 16px;
	border-radius: 30px;
	padding: 8px 30px;
	background-color: rgba(236, 219, 223, 0.5);
	text-transform: uppercase;

	&:hover, &:focus, &.hover {
		color: #fff;
		background-color: #830D29;
	}
}