%listreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

%clearfix {
 &:after {
    content: '';
    display: block;
    clear: both;
  }
}

// %block {
// 	padding: 20px 25px;
// 	box-shadow: $SHADOW_DEFAULT;
// 	background-color: $BG_COLOR;
// 	border-radius: $BORDER_RADIUS;
// }

%icon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icon {
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



%close {
  display: inline-block;
  border: none;
  background: none;
  padding: 5px;
  cursor: pointer;
  line-height: .5em;
  font-size: 35px;
  color: #757575;
}


%link {
  position: relative;
  line-height: 19px;
  font-size: 16px;
  color: $GRAY_1;
  transition: color .3s ease-in-out;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0; right: 0;
    display: inline-block;
    margin-top: 1px;
    border-top: 1px solid $COLOR_NORMAL;
    transition: border-color .3s ease-in-out;
  }

  &:hover, &:focus, &.hover {
    color: $COLOR_NORMAL;
    text-decoration: none;
    &:after {
      border-color: $COLOR_NORMAL;
    }
  }
}

%control {
  height: 48px;
  background: none;
  @include placeholder {
    color: $FONT_PRIMARY;
  }
}