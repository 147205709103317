.finance-header {
	position: relative;
	padding-bottom: 30px;
}

.finance-amount {
	font-weight: bold;
	font-size: 40px;
	font-family: $FONT_FAMILY_2;
}

.finance-amount__currency {
	display: inline-block;
	width: 33px;
}

.finance-convert {
	display: flex;
	.finance-currency {
		display: inline-block;
		width: 16px;
	}
}

.finance-of {
	margin-right: 5px;
}

.finance-to {
	margin-left: 5px;
}

.finance-icon {
	position: absolute;
	top: 50%; right: 30px;
	transform: translateY(-50%);
	width: 55px;
}