body {
	color: #1D1D1B;
	font: $FONT_REGULAR $P $FONT_FAMILY;
}

a, a:hover, a:focus {
	text-decoration: none;
}

h1 {
	margin-bottom: 25px;
	line-height: 56px;
	font-weight: 500;
	font-size: $H1;
}
h2 {
	line-height: 35px;
	font-weight: 500;
	font-size: 32px;
	@media all and (min-width: $screen-min) {
		line-height: 45px;
		font-size: $H2;
	}
}
h3 {
	line-height: 33px;
	font-weight: $FONT_REGULAR;
	font-size: $H3;
}
h4 {
	line-height: 23px;
	font-weight: $FONT_REGULAR;
	font-size: $H4;
}
h5 {
	line-height: 14px;
	font-weight: 500;
	font-size: $H5;
	letter-spacing: 0.08em;
}
p {
	line-height: 28px;
	font-size: $P;
	// color: #495057;
}
small {
	line-height: 14px;
	font-size: $SMALL;
}

.link {
	@extend %link;
	&.link-reverse {
		color: #CED4DA;
	}
}

ul.list-primary {
	@extend %listreset;
}

ul.list-default {
	@extend %listreset;
	position: relative;
	padding-left: 15px + 4px;
	margin-bottom: 25px;
	line-height: 28px;
	&:before {
		content: '';
		position: absolute;
		top: 0; left: 0;
		bottom: 0;
		display: inline-block;
		width: 4px;
		background-color: $GRAY_1;
	}
	
	&.list-left {
		padding-left: 0;
		&:before {
			left: -19px;
		}
	}
}

.list-title {
	padding-left: 19px;
	margin-bottom: 15px;
	color: $GRAY_1;
	font-size: 20px;
}

.list-links li {
	padding: 0 12px;
}