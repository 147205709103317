.branches {
	padding-top: 25px;
}

.branch {
	margin-bottom: 45px;
}

.branch-pic {
	width: 160px;
	margin-bottom: 15px;
}

.branch-title {
	color: #000;
	max-width: 200px;
}