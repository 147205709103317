.radio label, label.radio-inline {
	padding-left: 36px;
	color: #1D1D1B;
	font-weight: 500;
}

.radio label .bmd-radio::after, label.radio-inline .bmd-radio::after {
	width: 26px; height: 26px;
	border: 1px solid #830D29;
	border-radius: 14px;
}

.radio label .bmd-radio::before, label.radio-inline .bmd-radio::before {
	left: 6px; top: 6px;
	width: 36px; height: 36px;
	background-color: #830D29;
}

.radio label input[type=radio]:checked ~ .bmd-radio::before, 
label.radio-inline input[type=radio]:checked ~ .bmd-radio::before {
	background-color: #830D29;
}

.radio label input[type=radio]:checked ~ .bmd-radio::after, 
label.radio-inline input[type=radio]:checked ~ .bmd-radio::after {
	border-width: 2px;
	border-color: #830D29;
}

.radio label input[type=radio][disabled] + .bmd-radio::after, 
.radio label input[type=radio]:disabled + .bmd-radio::after, 
fieldset[disabled] .radio label input[type=radio] + .bmd-radio::after, 
label.radio-inline input[type=radio][disabled] + .bmd-radio::after, 
label.radio-inline input[type=radio]:disabled + .bmd-radio::after, 
fieldset[disabled] label.radio-inline input[type=radio] + .bmd-radio::after {
	border-color: #DDE2E5;
	background: #DDE2E5;
}