.phone {
	position: relative;
	width: 300px;
	height: 600px;
	padding: 7px 19px 18px;
	&:before {
		content: '';
		position: absolute;
		left: -38px; top: -18px;
		right: -38px; bottom: -55px;
		z-index: -1;
		background: url('#{$svg}/iPhone X.svg') no-repeat;
		background-size: contain;
	}
	&:after {
		content: '';
		position: absolute;
		left: 0; top: 0;
		right: 5px; bottom: 0;
		border-radius: 50px;
		background: linear-gradient(154.1deg, rgba(255, 255, 255, 0.26) -0.22%, rgba(255, 255, 255, 0.0781197) 47.01%, rgba(255, 255, 255, 0) 100%);
		pointer-events: none;
	}
}

.phone-content {
	height: 100%;
	padding: 25px 0 0;
	border-radius: 35px 35px 25px 25px;
	overflow: hidden;
}