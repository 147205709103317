.answer {
	position: relative;
	padding: 15px 20px 30px;
	border: 1px solid #8E8E8D;
	border-radius: 16px;
	margin: 10px 0;
}

.answer-title {
	position: absolute;
	left: 20px; top: 0;
	transform: translateY(-50%);
	padding: 0 10px;
	background-color: $COLOR_BG;
	font-size: 14px;
}

.answer-body {
	p {
		margin: 0;
	}
}

.answer-name {
	position: absolute;
	left: 20px;
	bottom: 0;
	transform: translateY(50%);
	padding: 0 10px;
	background-color: $COLOR_BG;
	font-size: 14px;
}