.steps {
	.step:not(:last-child) {
		margin-bottom: 35px;
	}
}

.step {
	display: flex;
	align-items: center;
}

.step-order {
	flex: 0 0 65px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 65px;
	height: 65px;
	border: 3px solid #C6C6C6;
	border-radius: 50%;
	color: #000;
	font-family: $FONT_FAMILY_2;
	font-size: 40px;
}

.step-desc {
	color: #000;
	font-size: 22px;
	margin-left: 30px;
}