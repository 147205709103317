.info-card {
	position: relative;
	display: flex;
	align-items: flex-start;
	margin-bottom: 30px;
	border-radius: 8px;
	padding: 18px 20px;
	background-color: #fff;
	overflow: hidden;
	border: 1px solid #830D29;
	box-shadow: 0px 2px 2px rgba(79, 8, 25, 0.24), 0px 0px 2px rgba(79, 8, 25, 0.12);

	@include animate(box-shadow);

	// &:before {
	// 	content: '';
	// 	position: absolute;
	// 	left: -1px; right: -1px; 
	// 	bottom: -1px; top: -1px;
	// 	display: block;
	// 	background: linear-gradient(90deg, #830D29 0%, #E94E1B 100%);
	// 	z-index: -1;
	// }
	&:hover {
		box-shadow: 0px 8px 8px rgba(79, 8, 25, 0.24), 0px 0px 8px rgba(79, 8, 25, 0.12);
	}
}

.info-card__icon {
	flex: 0 0 55px;
	width: 55px;
}

.info-card__content {
	// flex-grow: 1;
	flex: 0 0 auto;
	margin-left: 20px;
}

.info-card__title {
	margin-bottom: 5px;
	font-weight: 500;
	font-size: 22px;
}

.info-card__txt {
	display: block;
	margin-bottom: 15px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.info-card__link {
	color: #830D29;
	font-weight: 500;
	text-transform: uppercase;
	&:hover {
		color: #830D29;
	}
}