// body:after {
// 	content: '';
// 	@extend %grid;
// }

@font-face {
    font-family: 'AGOpus';
    src: url('#{$fnt}/agopus.eot');
    src: url('#{$fnt}/agopus.woff') format('woff'), 
         url('#{$fnt}/agopus.ttf') format('truetype'), 
         url('#{$fnt}/agopus.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'AGOpus';
    src: url('#{$fnt}/agopus_bold.eot');
    src: url('#{$fnt}/agopus_bold.woff') format('woff'), 
         url('#{$fnt}/agopus_bold.ttf') format('truetype'), 
         url('#{$fnt}/agopus_bold.svg') format('svg');
    font-weight: bold;
    font-style: normal;
}


body {
	position: relative;
	color: $FONT_PRIMARY;
	background-color: $COLOR_BG;
}

*:focus {outline: none;}

.container-fluid {
	min-width: 320px;
	max-width: 1200px;
}

picture {
	display: block;
}

.img-contain,
.img-cover {
	display: block;
	width: 100%;
	height: 100%;
	margin: auto;
}

.img-contain {
	object-fit: contain;
}

.img-cover {
	object-fit: cover;
}


.font-weight-medium {
	font-weight: 500;
}

// .bg-light { background-color: $COLOR_LIGHT; }
// .bg-normal { background-color: $COLOR_NORMAL; }
// .bg-medium { background-color: $COLOR_MEDIUM; }

// .bg-light-inverse { background-color: $COLOR_LIGHT_2; }
// .bg-normal-inverse { background-color: $COLOR_NORMAL_2; }
// .bg-medium-inverse { background-color: $COLOR_MEDIUM_2; }

// .bg-gray-1 { background-color: $GRAY_1; }
// .bg-gray-2 { background-color: $GRAY_2; }
// .bg-gray-3 { background-color: $GRAY_3; }

// .shadow-1 {	box-shadow: $SHADOW_1; }
// .shadow-2 {	box-shadow: $SHADOW_2; }
// .shadow-3 {	box-shadow: $SHADOW_3; }
// .shadow-4 {	box-shadow: $SHADOW_4; }
// .shadow-5 {	box-shadow: $SHADOW_5; }
// .shadow-6 {	box-shadow: $SHADOW_6; }


//*********************************/
//******** PUSH THE FOOTER ********/
//********  TO THE BOTTOM  ********/
//*********************************/
html, body {
  height: 100%;
}
#wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 320px;
}
#content {
  flex: 1 0 auto;
}
#footer {
  flex: 0 0 auto;
}


.square {
	position: relative;
	width: 100%;
	height: 0;
	padding: 50% 0;
}

.square__inner {
	position: absolute;
	top: 0; left: 0;
	bottom: 0; right: 0;
	display: block;
}


.col-item {
	margin-bottom: 30px;
}

.items-row {
	margin-bottom: -30px;
}

.flex-grow-1 {
	flex-grow: 1;
}


@media all and (min-width: 420px) and (max-width: $screen-min - 1) {
	.col-xs-1 {
		flex: 0 0 8.33333%;
		max-width: 8.33333%;
	}
	.col-xs-2 {
		flex: 0 0 16.66667%;
		max-width: 16.66667%;
	}
	.col-xs-3 {
		flex: 0 0 25%;
		max-width: 25%;
	}
	.col-xs-4 {
		flex: 0 0 33.33333%;
		max-width: 33.33333%;
	}
	.col-xs-5 {
		flex: 0 0 41.66667%;
		max-width: 41.66667%;
	}
	.col-xs-6 {
		flex: 0 0 50%;
		max-width: 50%;
	}
	.col-xs-7 {
		flex: 0 0 58.33333%;
		max-width: 58.33333%;
	}
	.col-xs-8 {
		flex: 0 0 66.66667%;
		max-width: 66.66667%;
	}
	.col-xs-9 {
		flex: 0 0 75%;
		max-width: 75%;
	}
	.col-xs-10 {
		flex: 0 0 83.33333%;
		max-width: 83.33333%;
	}
	.col-xs-11 {
		flex: 0 0 91.66667%;
		max-width: 91.66667%;
	}
	.col-xs-12 {
		flex: 0 0 100%;
		max-width: 100%;
	}
}


.backdrop {
	position: fixed;
	left: 0; right: 0;
	bottom: 0; top: 0;
	z-index: 9999;
	display: none;
	background-color: rgba(#000, .5);
	cursor: pointer;
	visibility: hidden;
	opacity: 0;
	@include animate(opacity, visibility);
	&.in {
		display: block;
		opacity: 1;
		visibility: visible;
	}
}


svg {
	.bg, .outline, .logo, .text, g {
		transition: all .3s;
	}
	&:not(:hover) {
		.bg {
			fill: none;
		}
		.outline {
			opacity: .6;
		}
		.logo {
			fill: #212529;
			stroke: #FFECB3;
		}
		.text {
			fill: #212529;
			opacity: .6;
		}
		g {
			filter: none;
		}
	}
}
