#content {
    overflow: hidden;
}

.page-title {
    margin-bottom: 45px;
    color: #1D1D1B;
    line-height: 48px;
    font-weight: bold;
    font-size: 40px;
    font-family: $FONT_FAMILY_2;
}
    .page-title_inverse {
        color: #fff;
    }

.page-subtitle {
    padding-left: 30px;
    margin-bottom: 30px;
    color: #000;
    font-size: 24px;
}

.embed-content {
    display: flex;
    align-items: flex-start;
    padding: 18px 24px;
    border-radius: 16px;
    background: linear-gradient(90deg, #830D29 0%, #E94E1B 100%);
    color: #fff;
    // svg {
    //     stroke: #fff;
    // }
}
    .embed-content__inner {
        margin-left: 30px;
        h3 {
            font-weight: 500;
            font-size: 24px;
        }
    }

.section-embed {
    // padding-top: 60px;
    padding: 60px 0;
    background: linear-gradient(90deg, #830D29 0%, #E94E1B 100%);
}


.phone-aside {
    position: relative;
    min-height: calc(100% + 655px);
    margin-top: -655px;
    .phone-block {
        position: sticky;
        top: 0;
    }
    .phone {
        z-index: 1;
        margin: 0 auto;
    }
}

.phone-bg {
    position: relative;
    height: 100%;
    &:before {
        content: '';
        position: absolute;
        left: 50%; bottom: -2px;
        transform: translateX(-50%);
        display: block;
        width: 670px;
        height: 230px;
        background: url('#{$img}/phone_bg.png') no-repeat bottom center;
        background-size: contain;
    }
}
    .phone-bg_inverse {
        &:before {
            top: -1px; bottom: auto;
            transform: translateX(-50%) rotate(180deg);
        }
    }


.phone-pulse {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}




.circle-1 {
    width: 390px; height: 390px;
    border-radius: 50%;
    background-color: rgba(255, 128, 73, 0.5);
    color: #fff;
    line-height: 100px;
    font-size: 1.3rem;
    text-align: center;
}

.circle-1::after, 
.circle-1::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 128, 73, 0.5);
    border-radius: 50%;
    z-index: -1;
    animation: grow 3s ease-in-out infinite;
}


.circle-1::before {
    // background: rgba(255, 128, 73, 0.5);
    animation-delay: -0.5s;
}

// .circle-1::after {
//     background: rgba(255, 128, 73, 0.5);
// }


@keyframes grow {
  0% { -webkit-transform: scale(1, 1); transform: scale(1, 1); opacity: 1; }
  100% { -webkit-transform: scale(1.8, 1.8); transform: scale(1.8, 1.8); opacity: 0; }
}