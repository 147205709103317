.feedback-list {
	.feedback-item:not(:last-child) {
		margin-bottom: 30px;
	}
}

.feedback-item {
	padding: 15px 20px 0;
	border: 1px solid #8E8E8D;
	border-radius: 8px;
	background-color: #fff;

	@include animate(box-shadow);

	&:hover {
		box-shadow: 0px 19px 38px rgba(79, 8, 25, 0.3), 0px 15px 12px rgba(79, 8, 25, 0.22);
	}
}

.feeback-item__body {}

.feedback-item__footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.feedback-item__num {
	background-color: #C6C6C6;
	border-radius: 100px;
	padding: 5px 14px;
	font-size: 14px;
}