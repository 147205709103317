.persons {
	.person-item {
		height: calc(100% - 30px);
		margin-bottom: 30px;
	}
}

.person-item {
	display: block;
	padding: 16px;
	border: 1px solid #F4A68D;
	border-radius: 16px;
	color: #fff;
	text-align: center;

	@include animate(background-color color box-shadow);

	&:hover {
		background-color: #F4A68D;
		color: #1D1D1B;
		box-shadow: 
			0px 19px 38px rgba(79, 8, 25, 0.3), 
			0px 15px 12px rgba(79, 8, 25, 0.22);
	}
}

.person-item__pic {
	margin-bottom: 20px;
}

.person-item__desc {
	line-height: 1.1;
	margin-bottom: 10px;
}

.person-item__more {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.person-item__title {
	display: block;
	font-size: 40px;
	font-family: $FONT_FAMILY_2;
}

.person-item__subtitle {
	display: block;
}

.person-item__link {
	font-size: 14px;
	text-transform: uppercase;
}