%col {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

%grid {
	position: fixed;
	top: 0; left: 50%; bottom: 0;
	transform: translateX(-50%);
	z-index: 99999999;
	width: 100%;
	max-width: 1200px;
	min-width: 320px;
	background: grid();
	pointer-events: none;
}

%hide_control {
	position: absolute;
	left: 0;
	z-index: -1;
	width: 0;
	height: 0;
	padding: 0;
	margin: 0;
	overflow: hidden;
	pointer-events: none;
	opacity: 0;
}

%img-responsive {
	display: block;
	height: auto;
	max-width: 100%;
}

@mixin col {
	position: relative;
	width: 100%;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 15px;
}

@mixin col-1 {
	flex: 0 0 8.33333%;
	max-width: 8.33333%;
}
@mixin col-2 {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}
@mixin col-3 {
	flex: 0 0 25%;
	max-width: 25%;
}
@mixin col-4 {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}
@mixin col-5 {
	flex: 0 0 41.66667%;
	max-width: 41.66667%;
}
@mixin col-6 {
	flex: 0 0 50%;
	max-width: 50%;
}
@mixin col-7 {
	flex: 0 0 58.33333%;
	max-width: 58.33333%;
}
@mixin col-8 {
	flex: 0 0 66.66667%;
	max-width: 66.66667%;
}
@mixin col-9 {
	flex: 0 0 75%;
	max-width: 75%;
}
@mixin col-10 {
	flex: 0 0 83.33333%;
	max-width: 83.33333%;
}
@mixin col-11 {
	flex: 0 0 91.66667%;
	max-width: 91.66667%;
}
@mixin col-12 {
	flex: 0 0 100%;
	max-width: 100%;
}