.certificate {
	display: block;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0px 2px 2px rgba(79, 8, 25, 0.24), 0px 0px 2px rgba(79, 8, 25, 0.12);
}

.certificate-more {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - 30px);
	border: 1px solid #830D29;
	border-radius: 4px;
	color: #830D29;
	text-align: center;
}

.certificate-more__icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

.certificate-more__link {
	display: block;
	margin-bottom: 25px;
	font-size: 16px;
}