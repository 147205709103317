.documents-list {
	.document:not(:last-child) {
		margin-bottom: 16px;
	}
}

.document {
	display: flex;
	align-items: center;
	padding: 12px 16px 16px;
	border: 1px solid #C6C6C6;
	border-radius: 8px;
	background: #fff;
	color: #1D1D1B;

	@include animate(border-color background-color color);

	&:hover {
		border-color: #830D29;
		background-color: #FAF6F7;
		color: #830D29;
	}
}

.document-icon {
	width: 48px;
	height: 48px;
}

.document-title {
	margin-left: 20px;
	font-weight: 500;
}