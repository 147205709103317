.btn {
	position: relative;
	display: inline-block;
	min-width: 130px;
	cursor: pointer;
	overflow: hidden;
	text-align: center;
	padding: 10px 20px;
	border: 1px solid transparent;
	border-radius: 30px;
	font-weight: 500;
	font-size: 16px;
	line-height: 21px;
	letter-spacing: 0.0357143em;
	text-transform: uppercase;

	@include animate(color border-color background-color box-shadow);

	&:hover, &:focus {
		border-color: transparent;
	}

	&.btn-default {
		color: #830D29;
		box-shadow: 0px 8px 8px rgba(79, 8, 25, 0.24), 0px 0px 8px rgba(79, 8, 25, 0.12);
		border-color: #fff;
		background-color: #fff;

		&:not(:disabled):not(.disabled) {
			&:hover, &.hover,
			&:focus, &:active:focus {
				color: #830D29;
				background: #fff;
				border-color: #fff;
				box-shadow: 0px 19px 38px rgba(79, 8, 25, 0.3), 0px 15px 12px rgba(79, 8, 25, 0.22);
			}

			&:active, &.active {
				color: #830D29;
				background-color: #fff;
				border-color: #fff;
				box-shadow: 0px 19px 38px rgba(79, 8, 25, 0.3), 0px 15px 12px rgba(79, 8, 25, 0.22);
			}
		}
	}

	&.btn-primary {
		background: linear-gradient(90deg, #830D29 0%, #E94E1B 100%);
		border-color: #830D29;
		box-shadow: 0px 2px 2px rgba(79, 8, 25, 0.24), 0px 0px 2px rgba(79, 8, 25, 0.12);
		color: #fff;

		&:not(:disabled):not(.disabled) {
			&:hover, &.hover,
			&:focus, &:active:focus {
				background: linear-gradient(90deg, #E94E1B 0%, #AF1200 100%);
				border-color: #E94E1B;
				color: #fff;
				box-shadow: 0px 19px 38px rgba(79, 8, 25, 0.3), 0px 15px 12px rgba(79, 8, 25, 0.22);
			}

			&:active, &.active {
				background: linear-gradient(90deg, #E94E1B 0%, #AF1200 100%);
				border-color: #E94E1B;
				color: #fff;
				box-shadow: 0px 19px 38px rgba(79, 8, 25, 0.3), 0px 15px 12px rgba(79, 8, 25, 0.22);
			}
		}
	}


	&.btn-secondary {
		color: #830D29;
		border-color: #830D29;

		&:not(:disabled):not(.disabled) {
			&:hover, &.hover,
			&:focus, &:active:focus {
				color: #FAFAFA;
				background: #830D29;
				box-shadow: none;
			}

			&:active, &.active {
				color: #FAFAFA;
				background-color: #830D29;
				box-shadow: none;
			}
		}
	}


		&.btn-outline-secondary {
		border-color: #830D29;
		background-color: transparent;
		color: #830D29;

		&:not(:disabled):not(.disabled) {
			&:hover, &.hover,
			&:focus, &:active:focus {
				color: #FAFAFA;
				background-color: #830D29;
				border-color: #830D29;
				box-shadow: none;
			}

			&:active, &.active {
				color: #FAFAFA;
				background-color: #830D29;
				border-color: #830D29;
				box-shadow: none;
			}
		}
	}


	&.btn-outline-primary {
		border-color: $COLOR_NORMAL;
		background-color: transparent;
		color: $GRAY_1;

		&:not(:disabled):not(.disabled) {
			&:hover, &.hover,
			&:focus, &:active:focus {
				color: $GRAY_1;
				background-color: $COLOR_NORMAL;
				border-color: $COLOR_NORMAL;
				box-shadow: none;
			}

			&:active, &.active {
				color: $GRAY_1;
				background-color: $COLOR_MEDIUM;
				border-color: $COLOR_MEDIUM;
				box-shadow: none;
			}
		}
	}

	&.btn-outline-default {
		border-color: #fff;
		background-color: transparent;
		color: #fff;

		&:not(:disabled):not(.disabled) {
			&:hover, &.hover,
			&:focus, &:active:focus {
				color: #000;
				background-color: #fff;
				border-color: #fff;
				box-shadow: none;
			}

			&:active, &.active {
				color: #000;
				background-color: #fff;
				border-color: #fff;
				box-shadow: none;
			}
		}
	}


	&.btn-fab {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: 0;
		width: 48px;
		height: 48px;
		padding: 0;
		border-radius: 50%;
		background: #FFF5D9;
		color: $GRAY_1;
		font-size: 24px;
		box-shadow: none;
		img, svg {
			display: block;
			width: 24px;
			height: auto;
		}
		svg { stroke: $GRAY_1; }
		img { width: 10px; }

		// &:not(:disabled):not(.disabled) {
		// 	&:hover, &.hover,
		// 	&:focus, &:active:focus, &.active {
		// 		// background: $COLOR_NORMAL;
		// 		// border-color: $COLOR_NORMAL;
		// 		background: #FFF5D9;
		// 	}
		// }
	}

	&.btn-link:hover {
		color: #830D29;
		text-decoration: none;
	}

	&.btn-lg {
		border-radius: 30px;
		// border-radius: 4px;
		padding: 9px 20px;
		font-size: 18px;
	}

	&.btn-wide {
		min-width: 160px;
	}

	.btn-prepend {
		margin-right: 20px;
	}
}