.pagination {
	display: inline-flex;
}

.page-item {}

.page-link {
	@extend .btn;
	@extend .btn-fab;
	margin-right: 16px;
	background-color: #fff;
	color: #212529;
	font-size: 18px;
	box-shadow: 0px 2px 2px rgba(79, 8, 25, 0.24), 0px 0px 2px rgba(79, 8, 25, 0.12);
}

.page-item.active .page-link {
	color: #212529;
	background-color: #ECDBDF;
	border-color: #ECDBDF;
}

.page-item.dots .page-link {
	border: none;
	background: none;
	box-shadow: none;
	cursor: default;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
	border-radius: 50%;
}