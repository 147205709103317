.advantages {}

.advantage {
	color: #fff;
}

.advantage-pic {
	max-width: 120px;
	margin-bottom: 25px;
}

.advantage-desc {
	line-height: 1.65;
}