.service-list {
	.service:not(:last-child) {
		margin-bottom: 30px;
	}
}

.service {
	display: flex;
	background: linear-gradient(90deg, #C8381F 0%, #E94E1B 100%);
	padding: 1px;
	border-radius: 8px;
	overflow: hidden;

	@include animate(color box-shadow);
	&:hover {
		color: #fff;
		box-shadow: 0px 19px 38px rgba(79, 8, 25, 0.3), 0px 15px 12px rgba(79, 8, 25, 0.22);

		.service-body {
			background-color: transparent;
		}
		.service-buy {
			background: #fff;
			color: #830D29
		}

		.service-detail {
			color: #fff;
		}
	}
}

.service-image {
	flex: 0 0 250px;
	width: 250px;
}

.service-body {
	flex-grow: 1;
	background-color: #fff;
	border-radius: 8px 0 0 8px;
	padding: 20px;

	@include animate(background-color);
}

.service-title {
	font-weight: bold;
	font-size: 32px;
	font-family: $FONT_FAMILY_2;
}

.service-info {
	margin-bottom: 25px;
}

.service-info__title {
	font-family: $FONT_FAMILY_2;
	font-weight: bold;
	font-size: 24px;
}

.serivce-info__desc {}

.service-actions {}

.service-buy {
	border: none;
	background: linear-gradient(90deg, #830D29 0%, #E94E1B 100%);
	box-shadow: 0px 2px 2px rgba(79, 8, 25, 0.24), 0px 0px 2px rgba(79, 8, 25, 0.12);
	color: #fff;
}

.service-detail {
	border: none;
	color: #830D29;
}