.checkbox label, label.checkbox-inline {
	padding-left: 36px;
}

.checkbox label .checkbox-decorator .check, label.checkbox-inline .checkbox-decorator .check {
	width: 26px; height: 26px;
	border: 1px solid #830D29;
	border-radius: 4px;
}

.checkbox label input[type=checkbox]:checked + .checkbox-decorator .check, 
label.checkbox-inline input[type=checkbox]:checked + .checkbox-decorator .check {
	color: #830D29;
	border-color: #830D29;
}

.checkbox label .checkbox-decorator .check:before, 
label.checkbox-inline .checkbox-decorator .check:before {
	margin-top: 0;
	margin-left: 10px;
}

.checkbox label input[type=checkbox]:checked + .checkbox-decorator .check:before, 
label.checkbox-inline input[type=checkbox]:checked + .checkbox-decorator .check:before {
	color: #830D29;
}


.checkbox label input[type=checkbox][disabled] + .checkbox-decorator .check, 
.checkbox label input[type=checkbox][disabled] .check, 
fieldset[disabled] .checkbox label input[type=checkbox] + .checkbox-decorator .check, 
fieldset[disabled] .checkbox label input[type=checkbox] .check, 
label.checkbox-inline input[type=checkbox][disabled] + .checkbox-decorator .check, 
label.checkbox-inline input[type=checkbox][disabled] .check, 
fieldset[disabled] label.checkbox-inline input[type=checkbox] + .checkbox-decorator .check, 
fieldset[disabled] label.checkbox-inline input[type=checkbox] .check {
    border-color: #DDE2E5;
    background: #DDE2E5;
}



@keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 26px, 0 32px 0 26px, -5px 5px 0 10px, 26px -12px 0 11px, 0 0 0 0 inset; }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 26px, 0 32px 0 26px, -5px 5px 0 10px, 26px -12px 0 11px, 0 0 0 0 inset; }
  50% {
    width: 0;
    height: 0;
    margin-top: 0px;
    margin-left: 10px;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 26px, 0 32px 0 26px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
    transform: rotate(45deg); }
  51% {
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-left: -2px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 10px inset;
    transform: rotate(0deg); }
  100% {
    width: 26px;
    height: 26px;
    margin-top: -2px;
    margin-left: -2px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    transform: rotate(0deg); } }