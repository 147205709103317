.service-open__content {
	padding: 0;
}

.service-open__header {
	display: flex;
	padding: 30px 0 0 30px;
	border-radius: 16px 16px 0px 0px;
	background: linear-gradient(90deg, #971E24 0%, #E94E1B 100%);
	color: #fff;
}

.service-open__header-inner {
	flex-grow: 1;
	padding-bottom: 30px;
	padding-right: 15px;
}

.service-open__image {
	flex: 0 0 250px;
	width: 250px;

	img {
		object-position: bottom;
	}
}

.service-open__body {
	padding: 30px;
}


.service-open__title {
	color: #fff;
}

.service-open__buy {
	@include animate(box-shadow);

	&, &:hover, &:focus, &:active:focus {
		border-color: #fff;
		background-color: #fff;
		color: #830D29;
	}

	&:hover {
		box-shadow: 0px 19px 38px rgba(79, 8, 25, 0.3), 0px 15px 12px rgba(79, 8, 25, 0.22);
	}
}

.service-open__list {
	position: relative;
	&:before {
		content: '';
		position: absolute;
		left: -30px; top: 0;
		bottom: 0;
		width: 8px;
		background-color: #8E8E8D;
	}
}

.service-open__dl {
	margin-bottom: 25px;
	dt {
		margin-bottom: 5px;
		font-weight: normal;
		font-size: 18px;
	}
	dd {
		font-size: 24px;
		font-weight: bold;
		font-family: $FONT_FAMILY_2;
	}
}