.review {
	max-width: 615px;
	position: relative;
	padding: 15px 20px 30px;
	border: 1px solid #8E8E8D;
	border-radius: 16px;
}

.review-body {
	p {
		margin: 0;
	}
}

.review-footer {}

.review-phone {
	position: absolute;
	left: 20px;
	bottom: 0;
	transform: translateY(50%);
	padding: 0 10px;
	background-color: $COLOR_BG;
	color: #000;
}

.review-phone__icon {
	display: inline-block;
	margin-right: 15px;
}