.features-section {
	position: relative;
	padding-top: 65px;
	padding-bottom: 40px;
	background-color: rgba(#000, .5);
	&:before {
		content: '';
		position: absolute;
		top: 0; right: 0;
		bottom: 0; left: 0;
		z-index: -1;
		display: block;
		background: linear-gradient(90deg, #830D29 0%, #E94E1B 100%);
	}
}

.features {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
	color: #fff;
}

.feature {
	flex: 0 0 50%;
	width: 50%;
	margin-bottom: 20px;
	padding: 0 15px;
	@media all and (min-width: $screen-md-min) {
		flex: 0 0 25%;
		width: 25%;
	}
}

.feature-qnt {
	display: block;
	line-height: 1;
	font-size: 46px;
	font-family: $FONT_FAMILY_2;
	@media all and (min-width: $screen-min) {
		// font-size: 65px;
	}
	@media all and (min-width: $screen-sm-min) {
		font-weight: bold;
	}
	@media all and (min-width: $screen-lg-min) {
		margin-bottom: 12px;
		font-size: 64px;
	}
}

.feature-desc {
	margin-bottom: 0;
	color: #fff;
	line-height: 1.2;
	font-size: 15px;
	@media all and (min-width: $screen-min) {
		font-size: 18px;
		line-height: 1.6;
	}
}

.feature-unit {
	font-size: .665em;
}