.questions {
	.question:not(:last-child) {
		margin-bottom: 20px;
	}
}

.question {
	position: relative;
	background: #fff;
	padding: 15px 15px;
	border: 1px solid #ECDBDF;
	border-radius: 8px;
	box-shadow: 
		0px 2px 2px rgba(79, 8, 25, 0.24), 
		0px 0px 2px rgba(79, 8, 25, 0.12);
	
	@include animate(box-shadow);

	&:before {
		content: '';
		position: absolute;
		left: -1px; top: 6px;
		bottom: 6px;
		width: 2px;
		background-color: #1D1D1B;
	}

	&:hover {
		box-shadow: 0px 19px 38px rgba(79, 8, 25, 0.3), 0px 15px 12px rgba(79, 8, 25, 0.22);
	}
}

.question-header {}

.question-title {
	margin: 0;
	line-height: 0;
}

.question-btn {
	position: relative;
	padding: 0 50px 0 0;
	background: none;
	border: none;
	color: #1D1D1B;
	line-height: 1.2;
	font-size: 18px;
	text-align: left;

	&[aria-expanded="true"] .question-icon {
		transform: translateY(-50%) rotate(180deg);
	}
}
	.question-icon {
		position: absolute;
		top: 50%; right: 3px;
		transform: translateY(-50%);
	}

.question-body {
	padding-top: 30px;
}