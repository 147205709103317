.header {
	padding-top: 15px;
	background: linear-gradient(90deg, #830D29 0%, #E94E1B 100%);
	color: #fff;
}

.h-top {
	text-align: right;
}

.h-bottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.h-logo {
	position: relative;
	top: -5px;
	display: inline-block;
	width: 160px;
}

.h-nav {
	display: flex;
	align-items: center;
}


.nav {
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
}

.nav-list {
	@extend %listreset;
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 0 -15px;
	& > li {
		padding: 0 15px;
	}
}

.nav-item {
	position: relative;
	display: inline-block;
	color: #fff;
	font-weight: 500;
	font-size: 16px;
	text-transform: uppercase;
	white-space: nowrap;
	&:after {
		content: '';
		position: absolute;
		left: 0; right: 100%;
		bottom: -20px;
		// display: none;
		height: 4px;
		background-color: #fff;
		border-radius: 2px;
		@include animate(right);
	}

	&:hover, &:focus {
		color: #fff;
	}

	&:hover, &:focus, .active > & {
		&:after {
			right: 0;
			// display: inline-block;
		}
	}
}


.h-sign {
	// padding: 0 
}


.h-tel {
	display: inline-block;
	margin-bottom: 10px;
	color: #fff;
	font-weight: 500;
	font-size: 18px;
	&:hover {
		color: #fff;
	}
}


.masthead {
	margin-top: 100px;
	margin-bottom: 135px;
}

.masthead-content {
	padding-bottom: 180px;
}

.main-title {
	margin-bottom: 35px;
	line-height: 1.55;
	font-size: 40px;
	font-family: $FONT_FAMILY_2;
}

.main-link {
	padding: 11px 50px;
}