.media-list {
	margin-bottom: 40px;
	.media-item:not(:last-child) {
		margin-bottom: 30px;
	}
}


.media {
	padding: 18px 24px;
	border: 1px solid #C6C6C6;
	background-color: #fff;
	border-radius: 16px;
	color: inherit;

	@include animate(box-shadow);

	&:hover {
		color: inherit;
		box-shadow: 
			0px 19px 38px rgba(#000, .3), 
			0px 15px 12px rgba(#000, .22);
	}
}

.media-icon {
	margin-right: 30px;
}

.media-body {
	h5 {
		font-weight: 500;
		font-size: 24px;
	}
	p {
		margin-bottom: 0;
	}
}