//* Path */
$img: '../img';
$svg: '../svg';
$fnt: '../fonts';

//* Typography */
$H1: 42px;
$H2: 36px;
$H3: 24px;
$H4: 20px;
$H5: 12px;
$P: 18px;
$SMALL: 12px;

$FONT_FAMILY: 'Montserrat', sans-serif;
$FONT_FAMILY_2: 'AGOpus', sans-serif;

$FONT_LIGHT: 300;
$FONT_REGULAR: 400;
$FONT_MEDIUM: 500;
$FONT_BOLD: 600;

$LETTER_SPACING: 0.0357143em;


//* Pallete */
$COLOR_THIN: #C18694;
$COLOR_LIGHT: #B84451;
$COLOR_NORMAL: #830D29;
$COLOR_MEDIUM: #4F0819;

$COLOR_THIN_2: #F4A68D;
$COLOR_LIGHT_2: #FF8049;
$COLOR_NORMAL_2: #E94E1B;
$COLOR_MEDIUM_2: #AF1200;

$GRAY_1: #1D1D1B;
$GRAY_2: #8E8E8D;
$GRAY_3: #C6C6C6;
$GRAY_4: #F4F4F4;

$SEC_1: #ECDBDF;
$SEC_2: #FFFFFF;
$SEC_3: #FAF6F7;


$COLOR_DANGER: #F44336;

$COLOR_BG: #fff;
$FONT_PRIMARY: $GRAY_1;



//* Shadow */
$SHADOW_1: 0px 2px 2px rgba(#000, .24), 0px 0px 2px rgba(#000, .12);
$SHADOW_2: 0px 8px 8px rgba(#000, .24), 0px 0px 8px rgba(#000, .12);
$SHADOW_3: 0px 19px 38px rgba(#000, .3), 0px 15px 12px rgba(#000, .22);
$SHADOW_4: 0px 2px 2px rgba(255, 111, 0, 0.24), 0px 0px 2px rgba(255, 111, 0, 0.12);
$SHADOW_5: 0px 8px 8px rgba(255, 111, 0, 0.24), 0px 0px 8px rgba(255, 111, 0, 0.12);
$SHADOW_6: 0px 19px 38px rgba(255, 111, 0, 0.3), 0px 15px 12px rgba(255, 111, 0, 0.22);

//* Borders */
// $BORDER_1: 1px solid $GRAY_4;
// $BORDER_2: 1px solid $COLOR_NORMAL;


//* Border radius */
$BORDER_RADIUS: 2px;



//* breakpoints */
$screen-min: 576px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;