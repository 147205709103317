.menu {
	margin-top: 50px;
}

.menu-list {
	@extend %listreset;
	border-left: 1px solid #8E8E8D;
	li {position: relative;}
}

.menu-item {
	position: relative;
	display: block;
	padding: 9px 0 9px 24px;
	color: #1D1D1B;
	line-height: 1.2;
	font-size: 18px;
	
	&:before {
		content: '';
		position: absolute;
		left: -6px; top: 50%;
		transform: translateY(-50%) rotateY(90deg);
		display: inline-block;
		width: 11px;
		height: 11px;
		border-radius: 50%;
		background-color: #830D29;
		box-shadow: 0px 2px 2px rgba(79, 8, 25, 0.24), 0px 0px 2px rgba(79, 8, 25, 0.12);
	}

	&:after {
		content: '';
		position: absolute;
		left: 0; right: -18px;
		top: 0; bottom: 0;
		z-index: -1;
		display: none;
		border-radius: 0px 30px 30px 0px;
		background: linear-gradient(90deg, #830D29 0%, #E94E1B 100%);
	}
	
	&:hover {
		color: #fff;
		&:after { display: block; }
	}

	&:hover, .active > & {
		&:before {
			transform: translateY(-50%) rotateY(0);
		}
	}
}
	.menu-item__caret {
		position: absolute;
		top: 9px; right: 0;
	}

.submenu-list {
	@extend %listreset;
	padding-left: 20px;

	.menu-item:before { left: -26px; }
	.menu-item:after  {	left: -20px; }
}