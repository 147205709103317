.referral-tl {
	margin-bottom: 5px;
	font-weight: 500;
	font-size: 22px;
}

.referral-copylink {
	margin-bottom: 0;
	transform: translateY(50%);
}

.ref-link {
	display: block;
	width: 100%;
	max-width: 400px;
	border: none;
	background: none;
	color: inherit;
}