.choose {
	position: relative;
	display: inline-block;
	margin-right: 5px;
}

.choose-head {
	position: relative;
	padding-right: 25px;
	line-height: 24px;
	font-weight: 500;
	font-size: 16px;
	cursor: pointer;
	&:after {
		content: '';
		position: absolute;
		top: 50%; right: 0;
		transform: translateY(-50%);
		display: inline-block;
		width: 12px;
		height: 6px;
		background: url('#{$img}/caret.svg') no-repeat;
		background-size: contain;
		// @include triangle(16px, #fff, down);
	}

	&.on:after {
		transform: translateY(-50%) rotate(180deg);
	}
}

.choose-list {
	@extend %listreset;
	position: absolute;
	top: 100%; left: 0;
	z-index: 1;
	display: none;
	padding: 10px 0;
	background-color: $COLOR_BG;
	box-shadow: $SHADOW_1;
}
	.choose-head.on ~ .choose-list {
		display: inline-block;
	}

.choose-item {
	display: inline-block;
	padding: 5px 15px;
	color: $GRAY_1;
	&:hover, &:focus {
		color: $GRAY_1;
		background-color: $COLOR_LIGHT;
	}
}