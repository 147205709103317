.profile {
	margin-top: 35px;
}

.profile-pic {
	display: block;
	margin: 0 auto 25px;
	width: 180px;
	height: 180px;
	border-radius: 50%;
	overflow: hidden;
}

.profile-info {
	text-align: center;
}

.profile-name {
	margin-bottom: 0;
	font-weight: 500;
}

.profile-id {
	margin-bottom: 15px;
	font-weight: 500;
}

.profile-status {
	margin-bottom: 10px;
	font-weight: 500;
}

.profile-price {
	display: inline-flex;
	align-items: center;
	margin-bottom: 0;
	font-size: 35px;
	font-family: $FONT_FAMILY_2;
}
	.profile-price__currency {
		margin-left: 10px;
	}

.profile-prof {
	margin-bottom: 15px;
	font-size: 16px;
}

.profile-actions {
	margin-bottom: 20px;
	border-top: 1px solid #F4A68D;
	display: flex;
	justify-content: space-between;
}

.profile-action {
	text-align: center;
	padding-top: 10px;
	border-top: 3px solid transparent;
	color: inherit;

	&.active, &:hover {
		border-top-color: #fff;
		color: inherit;
	}
}

.profile-action__icon {
	margin: 0 auto 10px;
	width: 24px;
}

.profile-action__desc {
	font-size: 14px;
}


.profile-message {
	z-index: 1;
	width: 100%;
	margin-bottom: 0;
	transform: translateY(50%);
}