.form-accordion {}

.form-accordion__header {
	background-color: #F4F4F4;
	margin: 0 -30px;
	padding: 14px 30px;
}

.form-accordion__title {
	margin: 0;
	line-height: 0;
}

.form-accordion__btn {
	position: relative;
	width: 100%;
	border: none;
	padding: 0 0 0 40px;
	background: none;
	color: #1D1D1B;
	line-height: 1.2;
	font-weight: 500;
	font-size: 18px;
	text-align: left;

	&[aria-expanded="true"] .form-accordion__icon {
		.on {
			display: none;
		}
		.off {
			display: inline-block;
		}
	}
}

.form-accordion__icon {
	position: absolute;
	left: 0; top: 50%;
	transform: translateY(-50%);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 24px;
	height: 24px;
	.off {
		display: none;
	}
}

.form-accordion__body {}