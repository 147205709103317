.callback {
	background-color: #fff;
}

.callback-head {
	background-color: #000;
	padding: 25px 30px 20px;
	margin-bottom: 10px;
	color: #fff;
}

.callback-pic {
	display: block;
	width: 180px;
	height: 180px;
	margin: 0 auto 7px;
	border-radius: 50%;
	overflow: hidden;
}

.callback-name {
	margin-bottom: 0;
	color: inherit;
	line-height: 1.3;
	font-size: 16px;
}

.callback-prof {
	display: block;
	margin-bottom: 8px;
	font-weight: 500;
	font-size: 18px;
}

.callback-phone {
	display: block;
	color: inherit;
	font-size: 18px;
	&:hover {
		color: inherit;
	}
}

.callback-body {
	padding: 10px 30px 0;
	color: #1D1D1B;
}

.callback-title {
	display: block;
	margin-bottom: 15px;
	font-size: 16px;
}

.callback-form {
	margin-bottom: 5px;
}

.callback-footer {
	padding: 0 40px;
}

.callback-promo {
	color: #1D1D1B;
	line-height: 1.3;
	font-size: 16px;
	& > span {
		font-weight: 500;
		font-size: 18px;
		text-transform: uppercase;
	}
}