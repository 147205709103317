.form-btn {
	margin-top: .5rem;
}

.form-group {
	display: flex;
	// height: 48px;
	padding: 1px 0;
	border: 1px solid #1D1D1B;
	margin-top: .5rem;
	border-radius: 24px;
	background: #fff;
	input.form-control,
	textarea.form-control {
		flex-grow: 1;
		padding: 5px 15px 5px 24px;
		background: none;
		color: #1D1D1B;
		font-weight: 500;
		font-size: 18px;
	}
	textarea.form-control {
		padding-top: 13px;
		height: 200px;
		resize: none;
	}
	.input-group-prepend {
		margin-right: 20px;
	}
	// .input-group-append {
	// 	flex-shrink: 0;
	// 	padding: 0 17px;

	// 	position: absolute;
	// 	top: 2px; right: 0;
	// }
	.bmd-label-floating {
		left: 1.5rem;
		top: .8rem;
		right: 42px;
		padding-right: 3px;
		color: #1D1D1B;
		font-weight: 500;
		font-size: 18px;
		text-transform: none;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.input-group-text svg {
		stroke: #830D29;
	}
	.bmd-help {
		bottom: -1.2rem;
		left: 1.5rem;
		text-transform: none;
	}

	&.is-focused, &.is-filled {
		border-color: #830D29;
		box-shadow: 0 0 0 1px #830D29;
		.bmd-label-floating {
			top: -.45rem;
			left: 1.5rem;
			right: auto;
			background-color: $COLOR_BG;
			color: #1D1D1B;
		}
	}

	&.has-error {
		border-color: $COLOR_DANGER;
		box-shadow: 0 0 0 1px $COLOR_DANGER;
		.input-group-text svg {
			stroke: $COLOR_DANGER;
		}
		.bmd-help {
			display: block;
			color: $COLOR_DANGER !important;
		}
	}

	&:hover, &:focus, &.hover {
		border-color: $COLOR_NORMAL;
		&.has-error {
			border-color: $COLOR_DANGER;
		}
	}
}