.payment {
	display: block;
	margin-bottom: 30px;
	cursor: pointer;
}

.payment-input {
	@extend %hide_control;
}

.payment-content {
	display: flex;
	align-items: center;
	padding: 12px 20px 16px;
	border: 1px solid #C6C6C6;
	border-radius: 8px;
	background-color: #fff;

	@include animate(background-color border-color box-shadow);

	input:checked + & {
		background-color: #FAF6F7;
		border-color: #830D29;
		box-shadow: 0px 8px 8px rgba(79, 8, 25, 0.24), 0px 0px 8px rgba(79, 8, 25, 0.12);
	}
}

.payment-icon {
	width: 48px;
}

.payment-desc {
	margin: 0 0 0 15px;
	font-weight: 500;
}